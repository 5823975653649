@use '@angular/material' as mat;
@use './assets/scss/colors' as *;
@import url('https://cdn.easi-training.fr/assets/css/icons.css');
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,400italic,600,700');

@include mat.elevation-classes();
@include mat.app-background();

html,
body {
    margin: 0;
    padding: 0;
    overflow: hidden;
    font-size: 14px;
    width: 100%;
    height: 100%;
    display: block;
    position: relative;
    z-index: -1;
}

* {
    font-family: 'Source Sans Pro', sans-serif;
}

.ngx-bar {
    top: 50px !important;
    background: #ff3366 !important;
}

// SCROLLBAR
::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

::-webkit-scrollbar-button:start:decrement,
::-webkit-scrollbar-button:end:increment {
    display: block;
    height: 0;
    background-color: transparent;
}

::-webkit-scrollbar-track-piece {
    border-radius: 8px;
}

::-webkit-scrollbar-thumb {
    height: 50px;
    background-color: #a8a8a8;
    border-radius: 8px;
    z-index: 9999;
}

::-webkit-scrollbar-corner {
    background-color: $blue2;
}

.mat-mdc-dialog-container {
    margin: 0 !important;
    padding: 0 !important;
    overflow: visible !important;
    .mdc-dialog__surface {
        overflow: visible !important;
        .mat-mdc-dialog-content {
            letter-spacing: unset !important;
            line-height: unset !important;
            font-size: unset !important;
            font-weight: unset !important;
        }
    }
}

.mat-tooltip {
    background-color: $grey1;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 14px;
    position: relative;
    overflow: unset !important;
    &:after {
        content: ' ';
        position: absolute;
        top: 50%;
        right: -15px;
        margin-top: -8px;
        border-width: 8px 8px 8px 8px;
        border-style: solid;
        border-color: transparent transparent transparent $grey1;
    }
}

.loadingEntry {
    height: 100vh;
    .point {
        width: 15px;
        height: 15px;
        border-radius: 10px;
        position: relative;
        top: 50%;
        animation: animationFrames linear 1s;
        animation-iteration-count: infinite;
        transform-origin: 50% 50%;
        &.point1 {
            position: absolute;
            left: calc(50% - 25px);
            background-color: #025576;
        }
        &.point2 {
            position: absolute;
            left: 50%;
            animation-delay: 0.2s;
            background-color: #12a2db;
        }
        &.point3 {
            position: absolute;
            left: calc(50% + 25px);
            animation-delay: 0.4s;
            background-color: #e0071b;
        }
    }
}

@keyframes animationFrames {
    0% {
        opacity: 0;
        transform: translate(0px, -20px);
    }
    19% {
        opacity: 1;
        transform: translate(0px, 0px);
    }
    53% {
        opacity: 1;
        transform: translate(0px, 0px);
    }
    66% {
        opacity: 0.53;
        transform: translate(0px, 0px);
    }
    80% {
        opacity: 0;
        transform: translate(0px, 0px);
    }
    100% {
        opacity: 0;
        transform: translate(0px, 0px);
    }
}

@media (max-width: 600px) {
    body {
        transform: scale(0.5) translate(-50%, -50%);
        -webkit-transform: scale(0.5) translate(-50%, -50%);
        -moz-transform: scale(0.5) translate(-50%, -50%);
        -ms-transform: scale(0.5) translate(-50%, -50%);
        -o-transform: scale(0.5) translate(-50%, -50%);
        width: 200%;
        height: 200%;
    }
}
