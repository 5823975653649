// Couleurs principales
$blue1: #102c3a;
$blue2: #136187;
$blue3: #199fe2;

$green1: #01a59b;

$red1: #e60000;

$rose1: #f36;

$black1: #131313;

$grey1: #424242;
$grey2: #a8a8a8;
$grey3: #f1f2f4;
$grey4: #ddd;
$grey5: #435f6d;

$grey: #f1f2f4;

$separator: #cbcbcb;
